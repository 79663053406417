@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

html,
body,
#root,
.app {
  height:100%;
  width:100%;
  font-family: 'Source Sans 3' , sans-serif ;

}
.content {
  height:100%;
  width:97%;
  overflow-x: hidden;
  font-family: 'Source Sans 3' , sans-serif ;

}
.app {
  display: flex;
  position:relative;
}
/* Scrollbar */
::-webkit-scrollbar {
  width:10px;
}
/* Track */
/* ::-webkit-scrollbar-track {
  background-color: #e0e0e0;
} */
/* Handle */

::-webkit-scrollbar-thumb {
  background-color: #888;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  right: 0;
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #356;
  border-radius: 8px;
  width: 12px;
  height: 12px;
}
label.Mui-focused {
  color:gray !important;
}
#zoomRange {
  border-radius: 8px;
  height: 4px;
  width: 150px;
  outline: none;
  -webkit-appearance: none;
}

input[type='range']::-webkit-slider-thumb {
  width: 8px;
  -webkit-appearance: none;
  height: 12px;
  background: #4cceac ;
  border-radius: 2px;
  border:2px solid #4cceac
}
#filled-basic,
#filled-basic-two {
  color:#8e8d8e
}
label {
  color: #8e8d8e !important;
}